import React, {useEffect, lazy, Suspense, useState} from 'react';
import './index.css'
import {
    Home,
    Contacts,
    Navbar,
    About,
    Experience,
    Portfolio,
    LinearIndeterminate,
    Projects, PdfViewer
} from "./components/exports";
// import Dashboard from "./dashboard";
import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom'
import {GetFromContext, setCookie} from "./globalContext/helperFunction";
import Footer from "./components/Footer/footer";
import TermsPage from "./dashboard/termsPage/termsPage";
import CookiesAlert from "./dashboard/cookiesAlert/cookiesAlert";
import {MyTextField} from "./dashboard/dashbordExports";
import {VscGistSecret} from 'react-icons/vsc'
import {TbHandFinger} from 'react-icons/tb'

const Dashboard = lazy(() => import('./dashboard'))


function App() {
    const {loader, cookies, setCookiesAccept, showWorkExperience, visibleComponents} = GetFromContext()
    const [password, setMyPassword] = useState('')
    const [showCo, setShowCo] = useState('')

    function showCookies() {
        setTimeout(() => {
            !cookies && setShowCo(true)
        }, 3000)
    }

    useEffect(() => {


    }, [loader, cookies])
    useEffect(() => {
        showCookies()


    }, [])

    function cookieAccept() {
        setCookiesAccept(true)
        setCookie('ca', true)
        setShowCo(false)
    }

    const setPassword = (v) => {
        setMyPassword(v.target.value)

    }

    return (
        <>
            {
                loader && <LinearIndeterminate/>
            }
            <div className="App">
                {
                    showCo && <CookiesAlert handleClick={cookieAccept}/>
                }


                <Suspense fallback={<LinearIndeterminate/>}>
                    {/*<Dashboard/>*/}
                    <Routes>
                        {/*<Route exact path={'/'} element={<Navigate replace to={'/home'}/>}/>*/}
                        <Route exact path={'/'} element={

                            <>
                                <Home/>
                                <section data-section={'experience'} id={'experience'}>
                                    <Projects/>
                                    <Experience/>
                                </section>

                                <Navbar/>
                                <About/>
                                <Contacts/>
                                <Footer/>
                            </>
                        }/>
                        <Route path={'/dashboard/*'} element={
                            <>
                                <Dashboard/>
                            </>
                        }
                        />
                        {/*<Home/>*/}
                        {/*<Navbar/>*/}
                        {/*<About/>*/}

                        {/*/!*<projects/>*!/*/}
                        {/*<Experience/>*/}
                        {/*<Portfolio/>*/}
                        {/*<Contacts/>*/}

                        {/*<Services/>*/}
                        <Route path={'/terms'} element={<TermsPage/>}/>
                    </Routes>
                </Suspense>
            </div>
        </>
    );
}

export default App;
