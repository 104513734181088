import React, {useEffect} from 'react';
import {StatisticsCard} from '../../components/helpsExports'
// import {PieChart} from '../dashbordExports'
import './style.scss'
import GridTable from "../components/gridTable/gridTable";
import {GetFromDashboardContext} from "../../globalContext/helperFunction";


const Statistics = (props) => {
    const {lastRates, statistics, lastUpdates} = GetFromDashboardContext()

    useEffect(() => {

    }, [lastRates])
    return (
        <>
            <div className={'statisticsContainer'}>
                <div className="StatisticsSection">
                    <div className="headerContainer">
                        <h1>General Statistics</h1>
                    </div>
                    {/*<div className={'col-9'}>*/}
                    {/*    {clicks && <PieChart res={clicks}/>}*/}
                    {/*</div>*/}

                    <div className="d-flex align-content-stretch flex-wrap">
                        <div className={'col-6 col-md-2 p-1'}>
                            <StatisticsCard title={'Traffic Inbound'} amount={statistics?.traffic || ''}
                                            subtitle={'+10%'}/></div>
                        <div className={'col-6 col-md-2 p-1'}>
                            <StatisticsCard title={'New Clients'} amount={statistics?.new_clients || ''}
                                            subtitle={'+2%'}/>
                        </div>
                        <div className={'col-6 col-md-2 p-1'}>
                            <StatisticsCard title={'Email Sent'} amount={statistics?.emails || ''} subtitle={'+2%'}/>
                        </div>
                        <div className={'col-6 col-md-2 p-1'}>
                            <StatisticsCard title={'Contacted Me'} amount={statistics?.contacted_me || ''}
                                            subtitle={'+2%'}/></div>
                        <div className={'col-6 col-md-2 p-1' }>
                            <StatisticsCard title={'Reviews'} amount={statistics?.rate_submit || ''} subtitle={'+2%'}/>
                        </div>
                    </div>
                </div>
                <div className="StatisticsSection">
                    <div className="headerContainer">
                        <h1>Portfolio</h1>
                    </div>
                    <div className="detailsContainer justify-content-between">


                        <GridTable
                            title={'Last Rates'} data={lastRates && lastRates} withCrypt={true}
                            desc={'People who left a rate to my portfolio website'}
                        />
                        {
                            lastUpdates &&
                            <GridTable title={'Last Updates'} data={lastUpdates} withCrypt={true}
                                       desc={'People who updated my portfolio website data'}
                            />

                        }
                        {/*<Rating/>*/}
                    </div>
                </div>
            </div>
        </>
    )
};

export default Statistics;



