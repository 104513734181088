import React, {useEffect, useRef, useState} from 'react';


const LazyLoading = ({src, width, className, height, file, alt}) => {
    const placeholderRef = useRef(null)
    const [shown, setShown] = useState(false)
    useEffect(() => {
        const obsrv = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    setShown(true)
                    obsrv.unobserve(placeholderRef.current)
                }
            }
        )

        if (placeholderRef && placeholderRef.current) {
            obsrv.observe(placeholderRef.current)
        }
    }, [shown])

    return (
        <div className={'w-100'}  ref={placeholderRef}>
            {
                shown ?
                    <img
                        src={shown ? file ? src : require(`../../../assets/${src}`) : ''}
                        width={width || '100%'}
                        height={height || '100%'}
                        className={className}
                        alt={alt || ''}
                    />
                    :
                    <div className="spinner-border" role="status">
                        {/*<span className="sr-only">Loading...</span>*/}
                    </div>

            }
        </div>
    )
};

export default LazyLoading;
