import './style.scss'
import {Card} from "../helpsExports";
import {GetFromContext} from "../../globalContext/helperFunction";
import {useEffect, useRef} from "react";
import l from '../../assets/programming.svg'
const About = (props) => {
    const scrollSideBar = useRef()
    const {aboutMe, aboutMeWorking} = GetFromContext()
    useEffect(() => {
    }, [aboutMe, aboutMeWorking])

    useEffect(() => {
        let pressed = false
        let startX = 0
        let scrollLeftStart = 0
        scrollSideBar?.current.addEventListener('mousedown', function (e) {
            document.body.style.cursor='grabbing'
            pressed = true
            startX = e.clientX - scrollSideBar?.current.offsetLeft
            scrollLeftStart = scrollSideBar?.current.scrollLeft
        })
        scrollSideBar?.current.addEventListener('mouseleave', function (e) {
            pressed = false
            document.body.style.cursor='unset'
        })
        window.addEventListener('mouseup', function (e) {
            document.body.style.cursor='unset'
            pressed = false
        })
        scrollSideBar?.current.addEventListener('mousemove', function (e) {
            e.preventDefault()
            if (!pressed) {
                return
            } else {
                const x = e.pageX - scrollSideBar?.current.offsetLeft
                const walk = (x - startX) * 2
                scrollSideBar.current.scrollLeft = scrollLeftStart - walk
            }
        })

    }, [])
    return (
        <section id={'about'} data-section={'experience'}>
            <h5 className={'secondary-text-color'}>Get To Know</h5>
            <h2 className={'main-text-color'}>About Me</h2>
            <div className={'container-lg d-flex flex-wrap align-items-center'}>
                <div className={'col-12 col-md-5 d-none d-md-block'} style={{minWidth:'200px'}}>
                        {/*<LazyLoad src={urlGet()[0] ||  'me-about.jpg'} file={urlGet()[1]}/>*/}
                        {/*<img src={l} alt="" className={'img-fluid w-100'}/>*/}
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="773.11417" height="610.8036"
                         viewBox="0 0 773.11417 610.8036">
                        <path d="M434.6459,511.69953a17.86664,17.86664,0,0,0-19.78976-17.86231,17.20233,17.20233,0,0,0-2.34789.43277l-60.46411-51.82742,3.61319-19.47549-29.8018-9.65812-8.9854,31.2107a18.10285,18.10285,0,0,0,7.6802,20.20409l74.38136,47.04492a17.0684,17.0684,0,0,0,.09142,1.75175,17.86684,17.86684,0,0,0,21.691,15.50179,17.38164,17.38164,0,0,0,2.10507-.60774A17.92013,17.92013,0,0,0,434.6459,511.69953Z"
                              transform="translate(-213.44291 -144.5982)" fill="#9f616a"/>
                        <path d="M284.32471,571.80732h77.958c0,2.24085,80.34537,4.05949,80.34537,4.05949a20.88352,20.88352,0,0,1,3.426,3.24759,20.15185,20.15185,0,0,1,4.70964,12.99037v3.24759A20.30493,20.30493,0,0,1,430.4663,615.6498H284.32471a20.31509,20.31509,0,0,1-20.29744-20.29744v-3.24759A20.32567,20.32567,0,0,1,284.32471,571.80732Z"
                              transform="translate(-213.44291 -144.5982)" fill="#3f3d56"/>
                        <rect x="134.57286" y="470.24525" width="21.10934" height="136.39882" fill="#3f3d56"/>
                        <path d="M429.20555,752.939c0,2.5538-31.9879.98788-71.447.98788s-71.447,1.566-71.447-.98788,31.9879-22.80584,71.447-22.80584S429.20555,750.3851,429.20555,752.939Z"
                              transform="translate(-213.44291 -144.5982)" fill="#3f3d56"/>
                        <polygon points="196.707 589.916 216.613 589.914 226.084 538.282 196.703 538.284 196.707 589.916" fill="#9f616a"/>
                        <path d="M405.0721,753.81057l64.18948-.00238v-.81189a24.98376,24.98376,0,0,0-24.9835-24.98409h-.00258l-39.20459.00158Z"
                              transform="translate(-213.44291 -144.5982)" fill="#2f2e41"/>
                        <path d="M439.924,702.4959l39.68705-88.99646a32.61556,32.61556,0,0,0-14.31961-41.86823l-86.083-46.16083-39.2815-3.34115-36.2345-3.29358-.26125.44242c-.3342.56453-8.12493,14.0211-2.85869,28.77639,3.99457,11.19214,14.385,19.93431,30.88264,25.9839,38.50726,14.11941,82.99077,26.187,100.32844,30.719a5.60369,5.60369,0,0,1,3.5905,2.8575,5.68054,5.68054,0,0,1,.31874,4.54472L398.82211,694.997a7.307,7.307,0,0,0,6.18557,9.79034l27.55021,2.006q.35085.0333.69852.0333A7.3021,7.3021,0,0,0,439.924,702.4959Z"
                              transform="translate(-213.44291 -144.5982)" fill="#cbcbcb"/>
                        <polygon points="183.716 589.916 203.622 589.914 213.094 538.282 183.713 538.284 183.716 589.916" fill="#9f616a"/>
                        <path d="M392.08174,753.81057l64.18947-.00238v-.81189a24.98375,24.98375,0,0,0-24.98349-24.98409h-.00258l-39.20459.00158Z"
                              transform="translate(-213.44291 -144.5982)" fill="#2f2e41"/>
                        <path d="M425.30988,707.36729l39.68705-88.99646a32.61558,32.61558,0,0,0-14.31961-41.86824l-86.083-46.16082-39.2815-3.34115-28.38066-2.46184.00388-.38932c-.33419.56452-8.12492,14.02109-2.85868,28.77638,3.99457,11.19214,6.266,19.93431,22.76366,25.9839,38.50726,14.11941,82.99077,26.187,100.32844,30.719a5.60372,5.60372,0,0,1,3.5905,2.8575,5.68056,5.68056,0,0,1,.31874,4.54473L384.208,699.86833a7.307,7.307,0,0,0,6.18557,9.79035l27.55021,2.006q.35085.0333.69852.0333A7.30209,7.30209,0,0,0,425.30988,707.36729Z"
                              transform="translate(-213.44291 -144.5982)" fill="#cbcbcb"/>
                        <path d="M350.51745,526.16257c1.90279-1.346,29.22832,12.99037,34.09971,1.6238-22.73314-56.83284-7.06762-81.37433-20.99278-113.96521a40.58128,40.58128,0,0,0-26.90124-23.02491l-36.12866-1.8236-.18712.09039a42.22708,42.22708,0,0,0-23.08923,44.79783c.12853,35.46886,21.35037,62.52084,13.92991,88.24705a8.31687,8.31687,0,0,0,.78574,6.46823,7.93084,7.93084,0,0,0,5.05929,3.74947l47.70464-6.36356a8.061,8.061,0,0,0,6.52214-1.26225Z"
                              transform="translate(-213.44291 -144.5982)" fill="#2f2e41"/>
                        <circle cx="99.20882" cy="190.99814" r="39.88209" fill="#9f616a"/>
                        <path d="M299.94834,372.20305c2.119.92721,6.45835-15.95756,4.51455-19.33465-2.89075-5.02229-2.71986-4.98621-4.63887-8.30894s-2.34839-7.87074.13667-10.79436,8.23583-2.53577,9.37,1.12986c-.72958-6.96252,6.15725-12.55932,12.97892-14.13211s14.01732-.5963,20.87662-1.99592c7.96055-1.62432,16.2431-8.29715,13.067-17.051a12.32977,12.32977,0,0,0-2.40493-3.95245c-3.66966-4.126-8.80288-5.88779-13.82183-7.59131-10.4411-3.54387-21.16788-7.13225-32.18706-6.741-18.10539.64277-35.21955,13.15863-41.31952,30.2176a42.47288,42.47288,0,0,0-1.75928,6.53634c-3.7822,20.33678,8.02752,40.39668,27.20868,48.1409Z"
                              transform="translate(-213.44291 -144.5982)" fill="#2f2e41"/>
                        <path d="M509.09721,436.33656l4.57346,27.15606a1.67186,1.67186,0,0,1-.09645.89874l-35.88045,89.65335a1.67163,1.67163,0,0,1-3.22212-.55063l-1.51993-36.02744a1.67151,1.67151,0,0,1,.12149-.69977l32.82692-80.782a1.67164,1.67164,0,0,1,3.19708.35168Z"
                              transform="translate(-213.44291 -144.5982)" fill="#3f3d56"/>
                        <polygon points="293.114 293.823 297.46 317.153 265.619 398.964 261.485 372.647 293.114 293.823" fill="#6c63ff"/>
                        <path d="M367.24714,553.62768c.78342,3.05836,103.64017,6.36858,104.70682,5.44356a21.67414,21.67414,0,0,0,2.47407-3.82766c1.07778-1.92515,2.07587-3.856,2.07587-3.856l-1.6905-32.52915L373.49208,508.0306s-4.48257,27.14579-5.88985,39.617A29.957,29.957,0,0,0,367.24714,553.62768Z"
                              transform="translate(-213.44291 -144.5982)" fill="#3f3d56"/>
                        <polygon points="248.114 377.702 248.123 405.727 203.309 402.541 205.292 374.657 248.114 377.702" opacity="0.1"
                                 // style="isolation:isolate"
                        />
                        <polygon points="189.717 382.557 189.988 382.523 187.872 394.437 162.975 392.667 163.825 380.716 189.717 382.557"
                                 opacity="0.1"
                                 // style="isolation:isolate"
                        />
                        <path d="M367.24714,553.62768c.78342,3.05836,103.64017,6.36858,104.70682,5.44356a21.67414,21.67414,0,0,0,2.47407-3.82766l-106.8258-7.596A29.957,29.957,0,0,0,367.24714,553.62768Z"
                              transform="translate(-213.44291 -144.5982)" opacity="0.1"
                              // style="isolation:isolate"
                        />
                        <path d="M325.94633,505.05131l86.76393,16.192a17.07827,17.07827,0,0,0,.73363,1.59338,17.90571,17.90571,0,0,0,11.49146,8.97255,17.72,17.72,0,0,0,14.39865-2.60867,17.8638,17.8638,0,0,0-18.475-30.51175,17.16787,17.16787,0,0,0-2.02,1.27194l-75.361-25.744-3.85719-19.42893L308.36136,456.856l3.239,32.56972A17.76744,17.76744,0,0,0,325.94633,505.05131Z"
                              transform="translate(-213.44291 -144.5982)" fill="#9f616a"/>
                        <path d="M307.30246,471.3831l45.42218-14.92881-10.59552-30.42282a18.135,18.135,0,0,0-20.10734-11.92359h0a18.13492,18.13492,0,0,0-15.15252,18.08864Z"
                              transform="translate(-213.44291 -144.5982)" fill="#2f2e41"/>
                        <path d="M595.44291,755.4018h-381a1,1,0,1,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-213.44291 -144.5982)"
                              fill="#cbcbcb"/>
                    </svg>
                        {/*<video src={l} muted autoPlay con/>*/}
                </div>
                <div className="aboutContent col-12 col-md-7">
                    <h2 className={'main-text-color typing-animation position-relative overflow-hidden'}>I would like to join your team!</h2>
                    <p className={'secondary-text-color'}>
                        {aboutMe &&  Array.from(aboutMe?.split('\n').map(
                            (s,index)=><span className={'m-2 d-block'} key={index}>{s}</span>
                        ))}
                    </p>
                    <div className={'cardWrapper'} ref={scrollSideBar}>
                        {aboutMeWorking && aboutMeWorking.map((e, index) =>
                            <Card data={e} key={index}/>
                        )}

                    </div>
                </div>


            </div>
        </section>
    )
};

export default About;
