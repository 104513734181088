import React from 'react';
import '../helpsComponentsStyles.scss'

import {AiFillGithub,AiFillLinkedin} from 'react-icons/ai';



const SocialMedia = (props) => {
    return(
        <div className={'socialMedia'}>

            {/*<LikeButton/>*/}
            <a href="https://github.com/rawadobied" title={'github'} target={'_blank'} rel="noreferrer" >

                <AiFillGithub className={'h4'}/>
            </a>
            <a href="https://www.linkedin.com/in/rawad-obied-50b50b171/" title={'linkedin'} target={'_blank'} rel="noreferrer" >
                <AiFillLinkedin className={'h4'}/>
            </a>
        </div>
    )
};

export default SocialMedia;
